import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { Helmet } from 'react-helmet-async'
import { withPreview } from 'gatsby-source-prismic'
import MapSlicesToComponents from '@walltowall/react-map-slices-to-components'

import { LocationTemplateQuery } from '../types.generated'
import { PickPartial } from '../types'
import { MapDataToPropsEnhancerArgs } from '../lib/mapSlicesToComponents'
import { pairsEq } from '../lib/pairsEq'
import { slicesMap } from '../slices/PageBody'

import { Layout } from '../components/Layout'
import { useSiteSettings } from '../hooks/useSiteSettings'

/**
 * `mapDataToPropsEnhancer` for `react-map-slices-to-components`. Props defined
 * here are added to all slices.
 *
 * @see https://github.com/WalltoWall/react-map-slices-to-components#providing-global-enhancers
 */
export const mapDataToPropsEnhancer = (
  props: Record<string, unknown> | undefined,
  {
    context,
    previousContext = {},
    nextContext = {},
    previousType,
    nextType,
    previousData,
  }: MapDataToPropsEnhancerArgs,
) => ({
  id:
    previousType === 'PageBodyAnchor'
      ? (previousData?.primary?.id as string)
      : undefined,
  previousSharesBg: pairsEq(
    previousContext.bgBottom ?? previousContext.bg,
    context.bgTop ?? context.bg,
  ) as boolean | boolean[],
  previousOverhangs: previousContext.overhangsBottom as boolean | boolean[],
  previousIsHeader: previousType === 'PageBodyHeader',
  nextSharesBg: pairsEq(
    context.bgBottom ?? context.bg,
    nextContext.bgTop ?? nextContext.bg,
  ) as boolean | boolean[],
  nextOverhangs: nextContext.overhangsTop as boolean | boolean[],
  nextIsFooter: nextType === 'PageBodyFooter',
  ...props,
})

/**
 * Props added to all slices by `mapDataToPropsEnhancer` for `LocationTemplate`.
 * Intersect this type with a slice's known props to get a complete list of
 * available props.
 *
 * @see https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#intersection-types
 */
export type LocationTemplateEnhancerProps = PickPartial<
  ReturnType<typeof mapDataToPropsEnhancer>,
  // Props defined in the enhancer function are marked as optional to remove
  // their necessity when using slices as React components.
  | 'id'
  | 'previousOverhangs'
  | 'previousSharesBg'
  | 'nextOverhangs'
  | 'nextSharesBg'
>

export const LocationTemplate = ({
  data,
  location,
}: PageProps<LocationTemplateQuery>) => {
  const siteSettings = useSiteSettings()
  const prismicLocation = data?.prismicLocation

  /**
   * `listMiddleware` for `react-map-slices-to-components`. Add or modify slices
   * for the page here.
   *
   * @see https://github.com/WalltoWall/react-map-slices-to-components#change-the-list-of-slices
   */
  const slicesMiddleware = <T,>(list: T[]) => [
    { __typename: 'PageBodyHeader', id: 'header' },
    {
      __typename: 'PageBodyLocationStaticMap',
      id: 'location-static-map',
      ...prismicLocation,
    },
    {
      __typename: 'PageBodyLocationDetails',
      id: 'location-details',
      ...prismicLocation,
    },
    ...list,
    { __typename: 'PageBodyFooter', id: 'footer' },
  ]

  /**
   * Metadata made available in a slice's `mapDataToProps` and
   * `mapDataToContext` functions.
   *
   * @see https://github.com/angeloashmore/react-map-to-components#maptocomponents
   */
  const meta = React.useMemo(
    () => ({
      rootData: data,
      location,
    }),
    [data, location],
  )

  return (
    <Layout>
      <Helmet>
        <title>
          {prismicLocation?.data?.title?.text ?? ''} | {siteSettings.siteName}
        </title>
        {siteSettings.locationMetaDescription &&
          prismicLocation?.data?.title?.text && (
            <meta
              name="description"
              content={siteSettings.locationMetaDescription.replace(
                /\$LOCATION/g,
                prismicLocation.data.title.text,
              )}
            />
          )}
      </Helmet>
      <MapSlicesToComponents
        list={[]}
        map={slicesMap}
        meta={meta}
        listMiddleware={slicesMiddleware}
        mapDataToPropsEnhancer={mapDataToPropsEnhancer}
      />
    </Layout>
  )
}

export default withPreview(LocationTemplate)

export const query = graphql`
  query LocationTemplate($id: String!) {
    prismicLocation(id: { eq: $id }) {
      _previewable

      # See src/slices/PageBodyLocationDetails.tsx for the fragment
      ...PageBodyLocationDetails

      # See src/slices/PageBodyLocationStaticMap.tsx for the fragment
      ...PageBodyLocationStaticMap
    }
  }
`
